/* eslint-disable jsx-a11y/tabindex-no-positive */
import React, { useEffect } from 'react'

import { Center, Container, VStack, Box } from '@chakra-ui/react'
import { useRouter } from 'next/router'

import { Button, Heading, Text } from 'tribe-components'
import { Trans, withTranslation } from 'tribe-translation'

import useSupportConnectSso from 'hooks/ssos/useSupportConnectSso'

import { setRedirectUrlCookie } from 'utils/authCookies.utils'

import { ssoIcon } from 'icons/svg'

const Login = () => {
  const { connectSso, loginURL, error } = useSupportConnectSso()
  const router = useRouter()
  const redirectUrl = router?.query?.redirectUrl

  // Redirect to a sign in page of the selected SSO
  useEffect(() => {
    if (redirectUrl) {
      setRedirectUrlCookie(String(redirectUrl))
    }
    if (loginURL) window.location.href = loginURL
  }, [loginURL, redirectUrl])

  return (
    <Box bg="bg.base" height="100vh">
      <Container maxWidth="sm">
        <Center minHeight="90vh" data-testid="login-form">
          <VStack spacing={6} minWidth={{ base: '100%', md: '360px' }}>
            <Heading fontSize="2xl" as="h1">
              <Trans
                i18nKey="network:auth.signin.heading"
                defaults="Sign in to your account"
              />
            </Heading>
            <VStack width="100%" spacing={4}>
              <VStack mt={3} w="full" align="stretch">
                <Button leftIcon={ssoIcon.google} onClick={connectSso}>
                  <Trans
                    i18nKey="signInWithGoogle"
                    defaults="Sign in with Google"
                  />
                </Button>
                {error && (
                  <Text color="danger.base">
                    {error?.message || (
                      <Trans
                        i18nKey="network:auth.errors.commonError"
                        defaults="Something Went wrong, please try again."
                      />
                    )}
                  </Text>
                )}
              </VStack>
            </VStack>
          </VStack>
        </Center>
      </Container>
    </Box>
  )
}

export default withTranslation()(Login)
