import React from 'react'

import { withTranslation } from 'tribe-translation'

import Login from '../../containers/SupportPanel/Login'

const LoginPage = () => <Login />

export default withTranslation('network')(LoginPage)

export const getServerSideProps = () => {
  return {
    props: {
      seo: null,
    },
  }
}
