import { useCallback } from 'react'

import { ApolloError, useLazyQuery } from '@apollo/client'

import {
  SupportSsoUrl,
  LOGIN_SUPPORT_WITH_SSO,
  LoginSupportWithSsoQuery,
  LoginSupportWithSsoQueryVariables,
} from 'tribe-api'

import { getRuntimeConfigVariable } from 'utils/config'

const useConnectSso = (): {
  data?: LoginSupportWithSsoQuery
  error?: ApolloError
  loading: boolean

  connectSso: () => void
  loginURL?: SupportSsoUrl['url']
} => {
  const callbackUrl = getRuntimeConfigVariable(
    'SHARED_SUPPORT_SSOS_CALLBACK_URL',
  )
  const [loginSupportWithSsoQuery, { data, error, loading }] = useLazyQuery<
    LoginSupportWithSsoQuery,
    LoginSupportWithSsoQueryVariables
  >(LOGIN_SUPPORT_WITH_SSO, {
    variables: {
      callbackUrl,
    },
  })

  const connectSso = useCallback(() => {
    loginSupportWithSsoQuery()
  }, [loginSupportWithSsoQuery])

  const loginURL = data?.loginSupportWithSso.url

  return {
    data,
    error,
    loading,

    connectSso,
    loginURL,
  }
}

export default useConnectSso
