import React from 'react'

import { Icon, IconProps } from 'tribe-components'

export const DragIcon = (props: IconProps) => (
  <Icon {...props}>
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.08301 2.5C6.39551 2.5 5.83301 3.0625 5.83301 3.75C5.83301 4.4375 6.39551 5 7.08301 5C7.77051 5 8.33301 4.4375 8.33301 3.75C8.33301 3.0625 7.77051 2.5 7.08301 2.5ZM7.08301 15C6.39551 15 5.83301 15.5625 5.83301 16.25C5.83301 16.9375 6.39551 17.5 7.08301 17.5C7.77051 17.5 8.33301 16.9375 8.33301 16.25C8.33301 15.5625 7.77051 15 7.08301 15ZM7.08301 8.75C6.39551 8.75 5.83301 9.3125 5.83301 10C5.83301 10.6875 6.39551 11.25 7.08301 11.25C7.77051 11.25 8.33301 10.6875 8.33301 10C8.33301 9.3125 7.77051 8.75 7.08301 8.75Z"
        fill="#2D3748"
      />
      <path
        d="M12.917 2.5C12.2295 2.5 11.667 3.0625 11.667 3.75C11.667 4.4375 12.2295 5 12.917 5C13.6045 5 14.167 4.4375 14.167 3.75C14.167 3.0625 13.6045 2.5 12.917 2.5ZM12.917 15C12.2295 15 11.667 15.5625 11.667 16.25C11.667 16.9375 12.2295 17.5 12.917 17.5C13.6045 17.5 14.167 16.9375 14.167 16.25C14.167 15.5625 13.6045 15 12.917 15ZM12.917 8.75C12.2295 8.75 11.667 9.3125 11.667 10C11.667 10.6875 12.2295 11.25 12.917 11.25C13.6045 11.25 14.167 10.6875 14.167 10C14.167 9.3125 13.6045 8.75 12.917 8.75Z"
        fill="#2D3748"
      />
    </svg>
  </Icon>
)
