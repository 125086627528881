import React from 'react'

export const GoogleLogo = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.9998 10.1778C17.9998 9.52001 17.9453 9.04001 17.8275 8.54224H10.1631V11.5111H14.6619C14.5712 12.2489 14.0814 13.36 12.993 14.1067L12.9777 14.206L15.4011 16.0458L15.569 16.0623C17.1109 14.6667 17.9998 12.6133 17.9998 10.1778Z"
      fill="#4285F4"
    />
    <path
      d="M10.1631 18.0001C12.3672 18.0001 14.2175 17.2889 15.569 16.0623L12.993 14.1067C12.3037 14.5778 11.3785 14.9067 10.1631 14.9067C8.00441 14.9067 6.17223 13.5112 5.51911 11.5823L5.42338 11.5902L2.90356 13.5014L2.87061 13.5911C4.21299 16.2045 6.97036 18.0001 10.1631 18.0001Z"
      fill="#34A853"
    />
    <path
      d="M5.51923 11.5822C5.34689 11.0844 5.24716 10.551 5.24716 9.99995C5.24716 9.4488 5.34689 8.91548 5.51016 8.41771L5.50559 8.3117L2.9542 6.36987L2.87072 6.40879C2.31746 7.49325 2 8.71106 2 9.99995C2 11.2888 2.31746 12.5066 2.87072 13.591L5.51923 11.5822Z"
      fill="#FBBC05"
    />
    <path
      d="M10.1631 5.09333C11.696 5.09333 12.73 5.74222 13.3195 6.28448L15.6234 4.08001C14.2085 2.79112 12.3672 2 10.1631 2C6.97036 2 4.21299 3.79555 2.87061 6.40888L5.51004 8.4178C6.17223 6.48891 8.00441 5.09333 10.1631 5.09333Z"
      fill="#EB4335"
    />
  </svg>
)

export default GoogleLogo
